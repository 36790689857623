
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import APISystem from "@/models/APISystem";
import APIForm from "@/components/APIForm.vue";
import * as ApiServices from "@/api/helpers/Api";
import * as ApiSystemServices from "@/api/helpers/ApiSystem";

@Component({ components: { APIForm } })
export default class APIDrawer extends Vue {
  @Prop({ required: true }) apiSystem!: APISystem;
  @Prop({ required: true }) show!: boolean;
  @Prop({ required: true }) create!: boolean;
  @Ref() readonly formRef!: APIForm;

  loading = false;

  updateApiSystem() {
    this.loading = true;
    this.formRef
      .validateForm()
      .then(valid => {
        if (valid) {
          const {
            id: apiSystemId,
            active,
            leadOffer,
            redirectUrl,
            apiId,
            systemId,
            pingtree,
          } = this.apiSystem;

          const updateApi = ApiServices.update(apiId.id as number, {
            name: apiId.name,
            description: apiId.description,
            code: apiId.code,
            clientId: apiId.clientId.id,
            offerTypeId: apiId.offerTypeId.id,
          });

          const updateApiSystem = ApiSystemServices.update(
            apiSystemId as number,
            {
              active,
              leadOffer,
              redirectUrl,
              systemId: systemId.id,
              pingtree,
            }
          );

          Promise.all([updateApi, updateApiSystem])
            .then(() => {
              Notify.successful(`Actualizado exitosamente.`);
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.loading = false;
              this.$emit("updateApiSystems");
            });
        }
      })
      .catch(() => {
        this.loading = false;
      });
  }

  createAPI() {
    this.loading = true;
    this.formRef
      .validateForm()
      .then(valid => {
        if (valid) {
          const { apiId } = this.apiSystem;
          ApiServices.create({
            name: apiId.name,
            description: apiId.description,
            code: apiId.code,
            clientId: apiId.clientId.id as number,
            offerTypeId: apiId.offerTypeId.id as number,
          })
            .then(res => {
              const { id } = res.data.data;
              const {
                active,
                leadOffer,
                redirectUrl,
                systemId,
                pingtree,
              } = this.apiSystem;
              ApiSystemServices.create({
                active,
                leadOffer,
                redirectUrl,
                apiId: id,
                systemId: systemId.id as number,
                pingtree,
              })
                .then(() => {
                  Notify.successful(`Creado exitosamente.`);
                })
                .catch(error => {
                  Notify.gebServerError(error);
                })
                .finally(() => {
                  this.loading = false;
                  this.$emit("updateApiSystems");
                });
            })
            .catch(error => {
              Notify.gebServerError(error);
            });
        }
      })
      .catch(() => {
        this.loading = false;
      });
  }
}

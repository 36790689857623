import { Gondor, Response } from "../Gondor";
import { Client } from "./Client";
import { OfferType } from "./OfferType";

export interface Api {
  id: number;
  name: string;
  description: null | string;
  code: string;
  client: Pick<Client, "id" | "name">;
  offerType: Pick<OfferType, "id" | "name">;
}

interface NewApi extends ApiSchema {
  id: number;
  updatedAt: Date;
  createdAt: Date;
}

interface ApiSchema {
  name: string;
  description?: string;
  code: string;
  clientId: number;
  offerTypeId: number;
}

// -------------------------------
export interface Setting {
  id: number;
  key: string;
  value: string;
  name: string;
  description: null | string;
  createdAt: string;
  updatedAt: string;
  apiSystemId?: number;
  cardId?: number;
}

interface SettingSchema {
  key: string;
  value: string;
  name: string;
  description?: string;
  apiSystemId?: number;
  cardId?: number;
}

export type Type = "api" | "card";

const ACTIONS = {
  API: "/api/",
  API_ITEM: "/api/{id}/",
  TYPE_SETTING: "/{type}Setting",
  TYPE_SETTING_ITEM: "/{type}Setting/{id}",
};

export const create = (body: ApiSchema) => {
  return Gondor.API.post<Response<NewApi>>(ACTIONS.API, body);
};

export const update = (id: number, body: Partial<ApiSchema>) => {
  return Gondor.API.patch(ACTIONS.API_ITEM.replace("{id}", String(id)), body);
};

// ----------------------------------------------------------------------
export const createSetting = (
  specificId: number,
  body: SettingSchema,
  type: Type
) => {
  const propName = type === "api" ? "apiSystemId" : "cardId";
  return Gondor.API.post(ACTIONS.TYPE_SETTING.replace("{type}", type), {
    ...body,
    [propName]: specificId,
  });
};

export const updateSetting = (
  configId: number,
  body: SettingSchema,
  type: Type
) => {
  return Gondor.API.patch(
    ACTIONS.TYPE_SETTING_ITEM.replace("{type}", type).replace(
      "{id}",
      String(configId)
    ),
    {
      key: body.key,
      value: body.value,
      name: body.name,
      description: body.description,
    }
  );
};

export const destoySetting = (specificId: number, type: Type) => {
  return Gondor.API.delete(
    ACTIONS.TYPE_SETTING_ITEM.replace("{type}", type).replace(
      "{id}",
      String(specificId)
    )
  );
};


import { Vue, Component, Prop } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import * as ApiServices from "@/api/helpers/Api";
import DeletePopover from "./DeletePopover.vue";
import Notify from "@/utils/notifications";
import Config from "@/models/Config";

interface FormRule {
  required?: boolean;
  type?: string;
  min?: number;
  max?: number;
  validator?: (
    rule: any,
    value: any,
    callback: (error?: string) => void
  ) => void;
  trigger?: string;
  pattern?: RegExp;
  message: string;
}

interface Options {
  value: string;
  label: string;
  type: ApiServices.Type;
}

@Component({
  components: {
    DeletePopover,
  },
})
export default class ConfigForm extends Vue {
  @Prop({ required: true }) readonly model!: ApiServices.Setting[];
  @Prop({ required: true }) readonly modelId!: number;
  @Prop({ required: true }) readonly type!: ApiServices.Type;
  @Prop({ required: true }) readonly loading!: boolean;
  @Prop({ required: false, default: false }) readonly wizard!: boolean;

  showDialog = false;
  newSetting = new Config();
  isSubmitting = false;
  creation = false;

  weekDays = ["D", "L", "M", "M", "J", "V", "S"];

  rules: { [key: string]: FormRule[] } = {
    key: [
      {
        required: true,
        message: "La llave es requerida",
        trigger: "blur",
      },
      {
        max: 25,
        message: "La llave no puede superar los 25",
      },
    ],
    value: [
      {
        required: true,
        message: "Es valor es requerido",
        trigger: "blur",
      },
    ],
    name: [
      {
        required: true,
        message: "El nombre es requerido",
        trigger: "blur",
      },
    ],
  };

  selectOptions: Options[] | [] = [];
  optionKeys: Options[] = [
    {
      value: "startDateConvertion",
      label: "Fecha de inicio de conversión (startDateConvertion)",
      type: "api",
    },
    {
      value: "endDateConvertion",
      label: "Fecha de finalización de conversión (endDateConvertion)",
      type: "api",
    },
    {
      value: "limitConvertion",
      label: "Limite de conversión (limitConvertion)",
      type: "api",
    },
    {
      value: "activeDays",
      label: "Días activo (activeDays)",
      type: "api",
    },
    {
      value: "startTimeActivity",
      label: "Hora de inicio de actividad (startTimeActivity)",
      type: "api",
    },
    {
      value: "endTimeActivity",
      label: "Hora de finalización de actividad (endTimeActivity)",
      type: "api",
    },
    {
      value: "tags",
      label: "Etiquetas (tags)",
      type: "card",
    },
    {
      value: "cardCategory",
      label: "Tipo de tarjeta (cardCategory)",
      type: "card",
    },
    {
      value: "costo",
      label: "Costo auto (costo)",
      type: "card",
    },
    {
      value: "modelo",
      label: "Modelo auto (modelo)",
      type: "card",
    },
    {
      value: "tasa",
      label: "Tasa de interes (tasa)",
      type: "card",
    },
    {
      value: "motor",
      label: "Motor del auto (motor)",
      type: "card",
    },
    {
      value: "puertas",
      label: "Puertas del auto (puertas)",
      type: "card",
    },
    {
      value: "hot",
      label: "Etiqueta HOT (hot)",
      type: "card",
    },
  ];

  changeKey(value: string) {
    if (value === "activeDays") {
      this.newSetting = {
        ...this.newSetting,
        value: ([] as unknown) as string,
      };
      return;
    }

    this.newSetting = { ...this.newSetting, value: "" };
  }

  createConfig() {
    this.openDialog(true);
    this.creation = true;
    this.newSetting = new Config();
  }

  editConfig(index: number) {
    this.openDialog(true);
    this.creation = false;
    const selected = this.model[index];
    this.newSetting = {
      id: selected.id,
      key: selected.key,
      value: this.transformValue(selected.value, selected.key),
      name: selected.name,
      description: selected.description,
      specificId: selected.apiSystemId || selected.cardId,
    } as Config;
  }

  openDialog(status: boolean) {
    this.showDialog = status;
  }

  closeDrawer() {
    this.$emit("closeDrawer", false);
  }

  findStartTimeActivity() {
    return this.model.find(setting => setting.key === "startTimeActivity");
  }

  isInitDateSet(type: "date" | "time"): boolean {
    if (type === "date") {
      return this.model.some(setting => setting.key === "startDateConvertion");
    }

    return this.model.some(setting => setting.key === "startTimeActivity");
  }

  isAlreadyCreated(key: string) {
    return this.model.some(setting => setting.key === key);
  }

  transformValue(value: string, key: string): string {
    try {
      if (key === "activeDays") {
        const parsedArray = JSON.parse(value);
        if (Array.isArray(parsedArray)) {
          return (parsedArray as unknown) as string;
        }

        return value;
      }

      return value;
    } catch (e) {
      return value;
    }
  }

  createSetting(formName: string) {
    this.isSubmitting = true;
    (this.$refs[formName] as ElForm).validate(valid => {
      if (valid && !this.wizard) {
        if (Array.isArray(this.newSetting.value)) {
          this.newSetting.value = JSON.stringify(this.newSetting.value);
        }
        if (
          this.newSetting.key === "startTimeActivity" ||
          this.newSetting.key === "endTimeActivity"
        ) {
          this.newSetting.value = `${this.newSetting.value}:00`;
        }
        ApiServices.createSetting(this.modelId, this.newSetting, this.type)
          .then(() => {
            Notify.successful("Configuración creada exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
            this.openDialog(false);
            this.$emit("updateModel", this.modelId);
          });
      } else if (valid && this.wizard) {
        this.isSubmitting = false;
        this.openDialog(false);
        this.$emit("create", this.newSetting);
      } else {
        this.isSubmitting = false;
        return false;
      }
    });
  }

  updateSetting(formName: string) {
    this.isSubmitting = true;
    (this.$refs[formName] as ElForm).validate(async valid => {
      if (valid && !this.wizard) {
        if (Array.isArray(this.newSetting.value)) {
          this.newSetting.value = JSON.stringify(this.newSetting.value);
        }
        if (
          this.newSetting.key === "startTimeActivity" ||
          this.newSetting.key === "endTimeActivity"
        ) {
          this.newSetting.value = `${this.newSetting.value}:00`;
        }

        const { id, ...restData } = this.newSetting;

        ApiServices.updateSetting(id as number, restData, this.type)
          .then(() => {
            Notify.successful("Configuración actualizada exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
            this.openDialog(false);
            this.$emit("updateModel", this.modelId);
          });
      } else if (valid && this.wizard) {
        this.isSubmitting = false;
        this.openDialog(false);
        this.$emit("update", this.newSetting);
      } else {
        this.isSubmitting = false;
        return false;
      }
    });
  }

  destroySetting(specificId: number) {
    if (!this.wizard) {
      ApiServices.destoySetting(specificId, this.type)
        .then(() => {
          Notify.successful("Configuración borrada exitosamente.");
        })
        .catch(error => {
          Notify.gebServerError(error);
        })
        .finally(() => {
          this.$emit("updateModel", this.modelId);
        });
    } else {
      this.$emit("delete", specificId);
    }
  }

  async mounted() {
    this.selectOptions = this.optionKeys.filter(
      option => option.type === this.type
    );
  }

  get title() {
    const types: {
      [key in ApiServices.Type]: string;
    } = {
      api: "api",
      card: "tarjeta",
    };
    return types[this.type];
  }

  get action() {
    const create = "Crear";
    const update = "Actualizar";
    return this.creation ? create : update;
  }
}

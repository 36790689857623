import { Count, Gondor, Response } from "../Gondor";

export interface Client {
  id: number;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null | Date;
}

interface ClientSchema {
  name: string;
  description?: string;
}

const ACTIONS = {
  CLIENT: "/client/",
};

export const find = (params?: object) => {
  return Gondor.API.get<Response<Count<Client>>>(ACTIONS.CLIENT, { params });
};

export const create = (body: ClientSchema) => {
  return Gondor.API.post(ACTIONS.CLIENT, body);
};


import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import { Column, Types } from "@/api/helpers/Column";
import * as ApiRuleServices from "@/api/helpers/ApiRule";
import * as CatalogServices from "@/api/helpers/Catalog";
import * as PreviousAnswerServices from "@/api/helpers/PreviousAnswer";

type ValueTypes = "text" | "number" | "switch" | "select" | "date";

type DataInputs = {
  [key in Types]: ValueTypes;
};

interface Comparator {
  label: string;
  value: ApiRuleServices.Operator;
  types: Types[];
}

@Component({})
export default class ApiSystemRules extends Vue {
  @Prop({ required: true }) readonly show!: boolean;
  @Prop({ required: true }) readonly apiSystemId!: number;
  @Prop({ required: true }) readonly rules!: ApiRuleServices.ApiRule[];
  @Prop({ required: true }) readonly columns!: Column[];

  loading = false;
  loadingDelete = false;
  loadingCatalog = false;
  catalog: CatalogServices.Catalog[] = [];
  previousAnswerCatalog: PreviousAnswerServices.PreviousAnswerCatalog[] = [];
  comparator: Comparator[] = [];
  comparators: Comparator[] = [
    {
      label: "Igual a",
      value: "=",
      types: ["id", "boolean", "date", "number", "string"],
    },
    {
      label: "Diferente de",
      value: "<>",
      types: ["id", "boolean", "date", "number", "string"],
    },
    {
      label: "Mayor que",
      value: ">",
      types: ["date", "number"],
    },
    {
      label: "Mayor o igual que",
      value: ">=",
      types: ["date", "number"],
    },
    {
      label: "Menor que",
      value: "<",
      types: ["date", "number"],
    },
    {
      label: "Menor o igual que",
      value: "<=",
      types: ["date", "number"],
    },
  ];

  dataTypes: ValueTypes[] = [];
  dataInputs: DataInputs = {
    string: "text",
    number: "number",
    boolean: "switch",
    id: "select",
    date: "date",
  };

  newRule() {
    this.rules.push({} as ApiRuleServices.ApiRule);
  }

  removeRule(index: number) {
    this.loadingDelete = true;
    const rule = this.rules[index];
    if (rule.id) {
      ApiRuleServices.destroy(rule.id)
        .then(() => {
          this.rules.splice(index, 1);
        })
        .catch(error => {
          Notify.gebServerError(error);
        });
    } else {
      this.rules.splice(index, 1);
    }

    this.loadingDelete = false;
  }

  cleanInvalidRules(rules: ApiRuleServices.ApiRule[]) {
    return rules.filter(rule => {
      return (
        typeof rule.columnId !== "undefined" &&
        typeof rule.operator !== "undefined" &&
        typeof rule.value !== "undefined"
      );
    });
  }

  splitRules(
    rules: ApiRuleServices.ApiRule[],
    propName: keyof ApiRuleServices.ApiRule
  ): [
    ApiRuleServices.ApiRule[],
    Omit<
      ApiRuleServices.ApiRule,
      "id" | "apiSystemId" | "createdAt" | "updatedAt"
    >[]
  ] {
    const withProperty: ApiRuleServices.ApiRule[] = []; //<--Update rule
    const withoutProperty: Omit<
      ApiRuleServices.ApiRule,
      "id" | "apiSystemId" | "createdAt" | "updatedAt"
    >[] = []; //<--New rule

    rules.forEach(rule => {
      if (propName in rule) {
        withProperty.push(rule);
      } else {
        withoutProperty.push(rule);
      }
    });

    return [withProperty, withoutProperty];
  }

  updateRules() {
    this.loading = true;
    const rules = this.cleanInvalidRules(this.rules);
    const [rulesToUpdate, rulesToCreate] = this.splitRules(rules, "id");

    const updateRulesPromises = rulesToUpdate.map(rule => {
      const { id, ...ruleData } = rule;
      return ApiRuleServices.update(id, {
        value: ruleData.value,
        operator: ruleData.operator,
        columnId: ruleData.columnId,
      });
    });

    const createRulesPromises = rulesToCreate.map(rule =>
      ApiRuleServices.create({ ...rule, apiSystemId: this.apiSystemId })
    );

    if (updateRulesPromises.length > 0 || createRulesPromises.length > 0) {
      Promise.all([...updateRulesPromises, ...createRulesPromises])
        .then(() => {
          Notify.successful(`Reglas actualizadas correctamente.`);
        })
        .catch(error => {
          Notify.gebServerError(error);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("updateRules", this.apiSystemId);
          this.closeDrawer();
        });
    } else {
      this.loading = false;
    }
  }

  changeColumn(columnId: number, index: number) {
    const foundColumn = this.columns.find(column => column.id === columnId);
    if (foundColumn) {
      this.dataTypes[index] = foundColumn.catalog
        ? this.dataInputs.id
        : this.dataInputs[foundColumn.dataType];
      this.comparator = this.comparators.filter(item => {
        return item.types.includes(foundColumn.dataType);
      });
      if (foundColumn.catalog && !foundColumn.previousAnswer) {
        this.getCatalog(foundColumn.id);
      } else if (foundColumn.catalog && foundColumn.previousAnswer) {
        this.getPreviousAnswerCatalog(foundColumn.id);
      }
    }
  }

  isPreviousAnswer(columnId: number): boolean {
    const column = this.columns.find(column => column.id === columnId);
    if (column) {
      return column.previousAnswer;
    }

    return false;
  }

  setDefaultDataTypes() {
    this.rules.map((rule, index) => {
      const column = this.columns.find(column => column.id === rule.columnId);
      if (column) {
        this.changeColumn(rule.columnId, index);
      }
    });
  }

  getCatalog(columnId: number) {
    const column = this.columns.find(column => column.id === columnId);
    if (column) {
      this.loadingCatalog = true;
      const catalogName = column.code.toLocaleLowerCase().replace("id", "");
      CatalogServices.find(catalogName)
        .then(res => {
          this.catalog = res.data.data;
        })
        .catch(error => {
          Notify.gebServerError(error);
        })
        .finally(() => {
          this.loadingCatalog = false;
        });
    }
  }

  getPreviousAnswerCatalog(columnId: number) {
    const column = this.columns.find(column => column.id === columnId);
    if (column) {
      this.loadingCatalog = true;
      PreviousAnswerServices.findCatalog(columnId)
        .then(res => {
          this.previousAnswerCatalog = res.data.data;
        })
        .catch(error => {
          Notify.gebServerError(error);
        })
        .finally(() => {
          this.loadingCatalog = false;
        });
    }
  }

  closeDrawer() {
    this.$emit("closeDrawer", false);
  }

  @Watch("rules", { immediate: true })
  onRulesChange(
    newValue: ApiRuleServices.ApiRule[],
    oldValue?: ApiRuleServices.ApiRule[]
  ) {
    this.setDefaultDataTypes();
  }
}

import { Gondor, Response } from "../Gondor";

export interface PreviousAnswerCatalog {
  value: string;
  columnId: number;
}

const ACTIONS = {
  catalog: "/catalog/previousAnswer",
};

export const findCatalog = (columnId: number) => {
  return Gondor.API.get<Response<PreviousAnswerCatalog[]>>(ACTIONS.catalog, {
    params: {
      columnId,
    },
  });
};

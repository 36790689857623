import { Gondor, Params, Response } from "../Gondor";

export interface Catalog {
  id: number;
  name?: string;
  description?: string;
}

const CatalogMap = {
  //lowercase: "path"
  state: "state",
  income: "income",
  debt: "debt",
  lender: "lender",
  profession: "profession",
  deposit: "deposit",
  maxprice: "maxPrice",
  monthlypayment: "monthlyPayment",
  vehiclesize: "vehicleSize",
  vehicletype: "vehicleType",
  feature: "feature",
  vehiclepurpose: "vehiclePurpose",
  sat: "sat",
  companyyears: "companyYears",
  companyemployees: "companyEmployees",
  companyactivity: "companyActivity",
  dedicatedtime: "dedicatedTime",
  investment: "investment",
  motivation: "motivation",
  schoolgrade: "schoolGrade",
  studiesfield: "studiesField",
  debttype: "debtType",
  gender: "gender",
  product: "product",
  loanreason: "loanReason",
};

type CatalogKeys = keyof typeof CatalogMap;

const ACTIONS = {
  CATALOG: "/{catalog}",
};

export const find = (catalogName: string, params?: Params) => {
  return Gondor.API.get<Response<Catalog[]>>(
    ACTIONS.CATALOG.replace(
      "{catalog}",
      CatalogMap[catalogName as CatalogKeys] ?? catalogName
    ),
    {
      params,
    }
  );
};
